<template>
   <header>
     <div class="container">
      <h1><router-link to="/">doradzamyauta.pl</router-link></h1>
      <nav>
        <ul>
          <li><router-link title="Strona główna" to="/" :class="{ active: activeRoute == 'Home' }">strona główna</router-link></li>
          <li><router-link title="Oferta" to="/oferta" :class="{ active: activeRoute == 'Offer' }">oferta</router-link></li>
          <li><router-link title="FAQ" to="/faq" :class="{ active: activeRoute == 'FAQ' }">faq</router-link></li>
          <li><router-link title="Kontakt" to="/kontakt" :class="{ active: activeRoute == 'Contact' }">Kontakt</router-link></li>
        </ul>
      </nav>
      <div class="menu" @click="toggleNavMobile()">
        <i class="material-icons">{{ menuIcon }}</i>
      </div>
     </div>
    <hr>
   </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      activeRoute: this.$route.name,
      menuIcon: 'menu',
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
    navMobile(){
      if(this.navMobile){
        this.menuIcon = 'close';
      }else{
        this.menuIcon = 'menu';
      }
    }
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: auto";
      }
    }
  }
}
</script>

<style scoped lang="scss">
  header { display: flex; align-items: center; justify-content: center; height: 100px; position: relative; }
  header div.container { display: flex; align-items: center; justify-content: space-between; width: 1000px; }
  header h1 { width: 270px; height: 50px; margin: 0 100px 0 0; padding: 0; background: url('../assets/logo_270.png') no-repeat 0 50%; text-indent: -9999px; }
  header h1 a { display: block; width: 100%; height: 100%; }
  nav ul { display: flex; margin: 0 0 0 100px; padding: 0; list-style: none; }
  nav ul li { margin: 0 30px; }
  nav ul li a { text-decoration: none; text-transform: uppercase; font-size: 13px; font-weight: 600; color: $black; }
  nav ul li a.active { color: $green; }
  nav ul li a:hover { color: $green; }
  hr { margin: 0; height: 2px; width: calc(1000px + ((100% - 1000px) / 2)); background-color: $gray; border: 0; position: absolute; bottom: 0; right: 0; }

  div.menu { display: none; justify-content: center; align-items: center; width: 60px; height: 60px; cursor: pointer; margin: 0 12px 0 20px; }
  div.menu i.material-icons { font-size: 36px; color: $black; }
  div.menu:hover i.material-icons { color: $green; }

  @media (max-width: 1100px) {
    nav { display: none; }
    div.menu { display: flex; }
  }

  @media (max-width: 1020px) {
    header h1 { margin: 0 20px; }
  }

  @media (max-width: 800px) {
    header { height: 70px; }
    header h1 { width: 192px; height: 70px; background: url('../assets/logo_192.png') no-repeat 0 8px; }

    div.menu i.material-icons { font-size: 32px; }
  }
</style>
