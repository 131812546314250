<template>
   <footer>
     <nav>
      <ul>
        <li>
          <router-link to="/kontakt">
            <div class="icon"></div>
            <p>kontakt</p>
          </router-link>
        </li>
        <li>
          <router-link to="/faq">
            <div class="icon"></div>
            <p>faq</p>
          </router-link>
        </li>
        <li>
          <a href="./polityka_prywatnosci_i_regulamin.pdf" target="_blank">
            <div class="icon"></div>
            <p>polityka prywatności i regulamin strony</p>
          </a>
        </li>
      </ul>
     </nav>
   </footer>
</template>

<script>
export default {
  name: 'TheFooter',
}
</script>

<style scoped lang="scss">
  footer { display: flex; align-items: center; justify-content: center; height: 240px; background: $grayDark; overflow: auto; }
  footer nav ul { display: flex; flex-wrap: wrap; margin: 0; padding: 0; list-style: none; }
  footer nav ul li a { display: flex; flex-direction: column; align-items: center; width: 120px; margin: 0 50px; text-decoration: none; }
  footer nav ul li a div.icon { width: 96px; height: 76px; }
  footer nav ul li:nth-child(1) a div.icon { background: url('../assets/footer/icon1.png') no-repeat 50% 50%; }
  footer nav ul li:nth-child(2) a div.icon { background: url('../assets/footer/icon2.png') no-repeat 50% 50%; }
  footer nav ul li:nth-child(3) a div.icon { background: url('../assets/footer/icon3.png') no-repeat 50% 50%; }
  footer nav ul li a p { margin: 10px 0 0 0; padding: 0; text-align: center; text-transform: uppercase; font-size: 11px; font-weight: 500; color: rgba(255, 255, 255, 0.5); }

  @media (max-width: 760px) {
    footer { height: initial; }
    footer nav { width: 100%; }
    footer nav ul { flex-direction: column; padding: 10px 0; }
    footer nav ul li a { margin: 0; width: 100%; padding: 10px;}
    footer nav ul li a div.icon { display: none; }
    footer nav ul li a p { margin: 0; }
  }
</style>
