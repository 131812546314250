<template>
  <div class="page">
    <transition name="fade">
    <div class="wrapper" v-show="loaded">
      <the-header></the-header>
      <router-view />
      <the-footer></the-footer>
      <the-nav-mobile></the-nav-mobile>
    </div>
    </transition>
    <div class="loader" v-if="!loaded">
      <div class="logo"></div>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheNavMobile from '@/components/TheNavMobile.vue'

export default {
  name: 'Wrapper',
  data(){
    return{
      loaded: 0,
    }
  },
  components: {
    TheHeader,
    TheFooter,
    TheNavMobile
  },
  computed: {
    content() {
      return this.$store.state.content;
    }
  },
  created(){
    if(this.content != 0){
      this.loaded = 1;
      this.$store.commit('changeLoaded', 1);
    }
  },
  mounted(){
    if(this.content != 0){
      this.loaded = 1;
      this.$store.commit('changeLoaded', 1);
    }
  },
  watch: {
    $route(to, from){
      if(this.content != 0){
        this.loaded = 1;
        this.$store.commit('changeLoaded', 1);
      }
    },
    content(){
      if(this.content != 0){
        let self = this;
        setTimeout(function(){ 
          self.loaded = 1; 
          self.$store.commit('changeLoaded', 1);
        }, 400);
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div.loader { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100vw; height: 100vh; }
  div.loader div.logo { width: 270px; height: 52px; background: url('../assets/logo_270.png') no-repeat 50% 50%; }

  .fade-enter {
    opacity: 0,
  }

  .fade-enter-active {
    transition: opacity 1s ease;
  }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $green;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


</style>
