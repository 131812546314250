<template>
  <nav v-if="navMobile">
    <ul>
      <li><router-link to="/" :class="{ active: activeRoute == 'Home' }" @click.native="close()">strona główna</router-link></li>
      <li><router-link to="/oferta" :class="{ active: activeRoute == 'Offer' }" @click.native="close()">oferta</router-link></li>
      <li><router-link to="/faq" :class="{ active: activeRoute == 'FAQ' }" @click.native="close()">faq</router-link></li>
      <li><router-link to="/kontakt" :class="{ active: activeRoute == 'Contact' }" @click.native="close()">kontakt</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNavMobile',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
    }
  },
  methods:{
    close(){
      this.$store.commit('toggleNavMobile', 0);
      document.body.style = "overflow-y: auto";
    },
  },
}
</script>
<style scoped lang="scss">
  nav { display: block; width: 100vw; height: calc(100vh - 100px); position: absolute; top: 100px; z-index: 4; background: #fff; overflow-y: auto; }
  nav ul { margin: 0; padding: 0; list-style: none; }
  nav ul li a { display: block; height: 80px; line-height: 80px; text-align: center; color: #000; text-decoration: none; text-transform: uppercase; font-size: 14px; letter-spacing: 1px; font-weight: 500; }
  nav ul li a:hover { color: $green; }
  nav ul li a.active { background: $green; color: #fff; }

  @media (min-width: 1101px) {
    nav { display: none; }
  }

  @media (max-width: 800px) {
    nav { height: calc(100vh - 70px);  top: 70px; }
  }
</style>
