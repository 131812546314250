<template>
  <div class="home" v-if="content">
    <div class="banner">
      <hr>
      <transition-group name="fade">
      <div class="static" :load="staticLoaded()" v-show="staticImg" :key="1"></div>
      <div class="animation" v-if="animationCars" :key="2">
        <img src="../assets/banner/car1.webp">
        <img src="../assets/banner/car2.webp">
        <img src="../assets/banner/car3.webp">
        <img src="../assets/banner/car4.webp">
      </div>
      </transition-group>
    </div>
    <section class="articles">
      <article>
        <div class="icon"></div>
        <div class="content">
          <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-góra', 'temat')))"></h3>
          <hr>
          <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-góra', 'treść')))"></p>
        </div>
      </article>
      <article>
        <div class="icon"></div>
        <div class="content">
          <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-środek', 'temat')))"></h3>
          <hr>
          <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-środek', 'treść')))"></p>
        </div>
      </article>
      <article>
        <div class="icon"></div>
        <div class="content">
          <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-dół', 'temat')))"></h3>
          <hr>
          <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'artykuł-dół', 'treść')))"></p>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      page: 'Strona główna',
      staticImg: 0,
      animationCars: 0,
    }
  },
  computed: {
    content() {
      return this.$store.state.content;
    }
  },
  methods: {
    staticLoaded(){
      this.staticImg = 1;
      let self = this;
      setTimeout(function(){ self.animationCars = 1; }, 2000);
    }
  }
}
</script>


<style scoped lang="scss">
  div.banner { height: 438px; position: relative; }
  div.banner hr { z-index: 1; margin: 0; padding: 0; position: absolute; right: 0; border: 0; outline: 0; width: calc(989px - 30px + ((100% - 989px) / 2)); height: 2px; background: $gray; z-index: 3; }
  div.banner hr:nth-child(1) { bottom: 40px; }
  div.banner div.static { width: 1182px; height: 398px; position: absolute; top: 40px; left: 0; z-index: 2; background: transparent url('../assets/home.webp') no-repeat 0 50%; }

  .articles { display: flex; flex-direction: column; align-items: center; }
  article { display: flex; align-items: center; justify-content: space-between; margin: 20px 0 40px 0; width: 1000px; }
  article div.icon { width: 186px; height: 218px; margin: 0 20px; background: gray; }
  article:nth-child(1) div.icon { background: url('../assets/icon1.png') no-repeat 50% 50%; }
  article:nth-child(2) div.icon { background: url('../assets/icon2.png') no-repeat 50% 50%; }
  article:nth-child(3) div.icon { background: url('../assets/icon3.png') no-repeat 50% 50%; }
  article div.content { margin: 0 20px; }
  article h3 { margin: 0 0 20px 0; padding: 0; text-transform: uppercase; color: $green; font-weight: 500; font-size: 20px; }
  article hr { margin: 0; padding: 0; border: 0; outline: 0; width: 100%; height: 2px; background: $gray; }
  article p { width: 700px; margin: 20px 0 0 0; padding: 0; text-align: justify; line-height: 21px; }

  .fade-enter {
    opacity: 0,
  }

  .fade-enter-active {
    transition: opacity 2s ease;
  }

  div.animation {
    width: 100vw;
    height: 398px;
    overflow: hidden;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
  }
  div.animation img {
    position: absolute;
  }
  div.animation img:nth-child(1) {
    top: 0;
    left: -100vw;
    animation: first-drive-to-left 30s infinite;
    animation-timing-function:linear
  }
  div.animation img:nth-child(2) {
    top: 0px;
    left: 100vw;
    animation: second-drive-to-right 30s infinite;
    animation-timing-function:linear
  }
  div.animation img:nth-child(3) {
    top: 0x;
    left: -100vw;
    animation: third-drive-to-left 30s infinite;
    animation-timing-function:linear
  }
  div.animation img:nth-child(4) {
    top: 0px;
    left: 100vw;
    animation: fourth-drive-to-right 30s infinite;
    animation-timing-function:linear
  }
  @keyframes first-drive-to-left {
    0% {
      left: -500px;
    }
    25% {
      left: calc(100vw + 500px);
    }
    100% {
      left: calc(100vw + 500px);
    }
  }
  @keyframes second-drive-to-right {
    0% {
      left: calc(100vw + 500px);
    }
    25% {
      left: calc(100vw + 500px);
    }
    50% {
      left: -500px;
    }
    100% {
      left: -500px;
    }
  }
  @keyframes third-drive-to-left {
    0% {
      left: -500px;
    }
    50% {
      left: -500px;
    }
    75% {
      left: calc(100vw + 500px);
    }
    100% {
      left: calc(100vw + 500px);
    }
  }
  @keyframes fourth-drive-to-right {
    0% {
      left: calc(100vw + 500px);
    }
    75% {
      left: calc(100vw + 500px);
    }
    100% {
      left: -500px;
    }
  }

  @media (max-width: 1182px) {
    div.banner div.static { width: 100%; }
  }

  @media (max-width: 1000px) {
    div.banner div.static { background: transparent url('../assets/home_medium.webp') no-repeat 50% 50%; }
    article { width: calc(100% - 40px); margin: 20px auto; }
    div.icon { display: none; }
    article p { width: 100%; }
    div.animation { display: none; }
  }

  @media (max-width: 450px) {
    div.banner { height: 270px; }
    div.banner hr:nth-child(1) { bottom: 20px; }
    div.banner div.static { height: 250px;  background: transparent url('../assets/home_small.webp') no-repeat 50% 50%; top: 20px; }
  }
</style>
