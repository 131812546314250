<template>
  <div class="offer" v-if="content">
    <section class="articles">
      <article class="artykuł-góra">
        <div class="content">
          <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'zawartość', 'tytuł')))"></h3>
          <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'zawartość', 'podtytuł')))"></h4>
          <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'zawartość', 'treść1')))"></p>
          <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'zawartość', 'treść2')))"></p>
        </div>
        <div class="graph">
          <div class="icon-wrapper">
            <div>
              <div>
                <div class="icon"></div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf1', 'podtytuł')))"></h4>
                <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf1', 'treść')))"></p>
              </div>
              <div><span @click="expandMore(0)">więcej <i class="material-icons" :class="{ rotate: isExpanded[0] }">expand_more</i></span></div>
            </div>
            <div class="more" key="more0" >
              <transition name="extend">
                <div v-if="isExpanded[0]">
                  <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'więcej1', 'treść')))"></p>
                </div>
              </transition>
            </div>
          </div>
          <div class="dash"></div>
          <div class="icon-wrapper">
            <div>
              <div>
                <div class="icon"></div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf2', 'podtytuł')))"></h4>
                <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf2', 'treść')))"></p>
              </div>
              <div><span @click="expandMore(1)">więcej <i class="material-icons" :class="{ rotate: isExpanded[1] }">expand_more</i></span></div>
            </div>
            <div class="more" key="more1">
              <transition name="extend">
                <div v-if="isExpanded[1]">
                  <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'więcej2', 'treść')))"></p>
                </div>
              </transition>
            </div>
          </div>
          <div class="dash"></div>
          <div class="icon-wrapper">
            <div>
              <div>
                <div class="icon"></div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf3', 'podtytuł')))"></h4>
                <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'graf3', 'treść')))"></p>
              </div>
              <div><span @click="expandMore(2)">więcej <i class="material-icons" :class="{ rotate: isExpanded[2] }">expand_more</i></span></div>
            </div>
            <div class="more" key="more2">
              <transition name="extend">
                <div v-if="isExpanded[2]">
                  <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-góra', 'więcej3', 'treść')))"></p>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div>
        </div>
      </article>
      <article>
        <transition name="fade">
        <div class="image" :load="staticLoaded()" v-show="staticImg">
          <div class="line"></div>
        </div>
        </transition>
        <div class="content">
          <div>
            <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'zawartość', 'tytuł1')))"></h3>
            <div>
              <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'zawartość', 'podtytuł1')))"></h4>
              <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'zawartość', 'treść1')))"></p>
            </div>
            <div>
              <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'zawartość', 'podtytuł2')))"></h4>
              <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'zawartość', 'treść2')))"></p>
            </div>
          </div>
          <div>
            <div>
              <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'lista', 'tytuł')))"></h3>
              <div v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'lista', 'lista1')))"></div>
              <div v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-środek', 'lista', 'lista2')))"></div>
            </div>
          </div>
        </div>
      </article>
      <article id="cennik">
          <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'tytuł')))"></h3>
          <div class="content">
            <div>
              <div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł1-1')))"></h4>
                <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'treść1')))"></p>
              </div>
              <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł1-2')))"></h4>
            </div>
            <div>
              <div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł2-1')))"></h4>
                <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'treść2')))"></p>
              </div>
              <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł2-2')))"></h4>
            </div>
            <div>
              <div>
                <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł3-1')))"></h4>
              </div>
              <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł-dół', 'zawartość', 'podtytuł3-2')))"></h4>
            </div>
          </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: [false, false, false],
      page: 'Oferta',
      staticImg: 0,
    }
  },
  computed: {
    content() {
      return this.$store.state.content;
    },
    loaded() {
      return this.$store.state.loaded;
    },
  },
  watch:{
    loaded(){
      this.$nextTick(() => {
        if(window.location.href.search('#cennik') != -1){
          var elmnt = document.getElementById("cennik");
          elmnt.scrollIntoView();
        }
      });
    },
  },
  methods: {
    expandMore(index) {
      this.$set(this.isExpanded, index, !this.isExpanded[index]);
    },
    staticLoaded(){
      this.staticImg = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep ul {
  list-style: none;
}
article:nth-child(1) div.content p:nth-of-type(2) {
  margin: 0;
}
article:nth-child(1) div.content p:nth-of-type(1) {
  margin-bottom: 0;
}
div.graph {
  display: flex;
  justify-content: space-between;
  position: relative;
}
div.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 20px;
  text-align: center;
}
div.icon-wrapper p {
  text-align: center;

}
div.icon-wrapper div.more p {
  text-align: left;

}
div.icon-wrapper div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
div.icon-wrapper div h4 {
  margin: 0;
  height: 66px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: $gray;
}
div.icon-wrapper div:nth-of-type(1) {
  height: 350px;
}

div.icon-wrapper div:nth-of-type(1) div:nth-of-type(1) div.icon {
  height: 170px;
  flex-shrink: 0;
}

div.icon-wrapper div:nth-of-type(1) div:nth-of-type(2) {
  width: 100%;
  display: flex;
  flex-direction: initial;
  align-items: center;
  justify-content: flex-end;
  color: $green;
}
div.icon-wrapper div:nth-of-type(1) div:nth-of-type(2) span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
}
div.icon-wrapper div.more {
  margin-top: 0;
  width: 300px;
  color: $gray;
}
div.icon-wrapper div.more div {
  width: 100%;
  padding: 0;
  margin: 0;
  border: 1px dotted $green;
  overflow: hidden;
  height: 100%;
}
div.icon-wrapper div.more div ::v-deep ul {
  padding: 0;
}
div.icon-wrapper div.more div p {
  margin: 15px;
  overflow: hidden;
}
div.icon-wrapper div span i.material-icons {
  transition: all .5s linear;
}
div.icon-wrapper div span i.rotate {
  transition: all .5s linear;
  transform: rotate(180deg);
}
div.icon {
  width: 200px;
  max-width: 200px;
  height: 200px;
  margin: 0 20px;
  background: gray;
}
div.icon-wrapper:nth-child(1) div.icon {
  background: url("../assets/icon8.png") no-repeat 50% 50%;
}
div.icon-wrapper:nth-child(3) div.icon {
  background: url("../assets/icon4.png") no-repeat 50% 50%;
}
div.icon-wrapper:nth-child(5) div.icon {
  background: url("../assets/icon7.png") no-repeat 50% 50%;
}
div.dash {
  height: 2px;
  width: 90px;
  background-color: $gray;
  position: absolute;
  top: 100px;
}
div.dash:nth-child(2) {
  left: 29%;
}
div.dash:nth-child(4) {
  left: 64%;
}
div.content h3 {
  font-weight: 400;
  color: $green;
}
div.content h4 {
  font-weight: 500;
  color: $gray;
}
article:nth-child(2) {
  display: flex;
}
article:nth-child(2) div.content {
  width: 45vw;
  height: 650px;
}
article:nth-child(2) div.content div:nth-of-type(2) {
  width: 35vw;
}
article:nth-child(2) div.content div:nth-of-type(3) div:nth-last-of-type(2) {
  width: 35vw;
}
article:nth-child(2) div.content div:nth-of-type(3) div:nth-last-of-type(1) {
  width: 27vw;
}
div.image {
  position: absolute;
  background: transparent url("../assets/offer.webp") no-repeat 0 50%;
  height: 650px;
  max-width: 800px;
  width: 60vw;
  right: 0;
}
.line {
  position: absolute;
  right: 80%;
  background-color: $gray;
  transform: skew(-31deg);
  height: 650px;
  width: 2px;
}
article:nth-child(2) div.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
article:nth-child(2) div.content div:nth-of-type(2) {
  margin-bottom: 23px;
}
article:nth-child(2) div.content div div div:nth-of-type(1) ::v-deep ul {
  margin: 0;
  padding: 0 50px 0 25px;
}
article:nth-child(2) div.content div div div:nth-of-type(1) ::v-deep ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $green; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size: 25px;
}
article:nth-child(2) div.content div div div:nth-of-type(1) ::v-deep ul li {
  font-size: 18px;
  font-weight: 500;
  color: $gray;
  line-height: 24px;
}
article:nth-child(2) div.content div div div:nth-of-type(2) ::v-deep ul li {
  font-size: 13px;
  line-height: 21px;
}
article:nth-child(3) div div {
  display: flex;
  justify-content: space-between;
}
article:nth-child(3) div div h4 {
  color: $green;
}
article:nth-child(3) div div div {
  display: flex;
  flex-direction: column;
}
article:nth-child(3) div div div h4 {
  color: $gray;
}

.extend-enter-active, .extend-leave-active {
  transition: all .5s linear;
  max-height: 350px;
}
.extend-enter, .extend-leave-to {
  // opacity: 20%;
  max-height: 0;
}

@media (max-width: 1000px) {
  div.dash { display: none; }
  div.graph {
    flex-direction: column;
    align-items: center;
  }
  div.graph div {
    width: 500px;
  }
  div.icon-wrapper div.more {
    width: 500px;
  }
  article:nth-child(2) {
    flex-direction: column;
    width: 100%;
  }
  div.image {
    // display: none;
    position: initial;
    background: transparent url("../assets/offer_medium.webp") no-repeat 50% 50%;
    background-size: 1000px;
    width: 100vw;
    max-width: 100vw;
    height: 300px;
    margin-top: 10px;
  }
  .line { display: none; }
  article:nth-child(2) div.content {
    width: calc(100% - 40px);
    margin: 20px auto;
    height: initial;
    margin-bottom: 0;
  }
  article:nth-child(2) div.content div:nth-of-type(2) {
    margin: 0;
    width: 100%;
  }
  article:nth-child(2) div.content div:nth-of-type(3) div:nth-last-of-type(2) {
    width: 100%;
  }
  article:nth-child(2) div.content div:nth-of-type(3) div:nth-last-of-type(1) {
    width: 100%;
  }
  article:nth-child(2) div.content div div div:nth-of-type(1) ::v-deep ul {
    margin: 0;
    padding: 0 0px 0 25px;
  }
  article:nth-child(3) div div {
    display: flex;
    flex-direction: column;
  }
  article:nth-child(3) div div h4 {
    margin-top: 0;
    margin-bottom: 35px;
  }
  article:nth-child(3) div div div h4 {
    margin-bottom: 10px;
  }
  article:nth-child(3) div div div p {
    margin-top: 0;
  }
}
@media (max-width: 600px) {
  div.graph div {
    width: 100%;
  }
  div.icon-wrapper div.more {
    width: 100%;
  }
}

@media (max-width: 450px) {
  div.image { height: 250px; background: transparent url("../assets/offer_small.webp") no-repeat 50% 50%; }
}

</style>
