<template>
  <div class="admin-wrapper">
    <the-header></the-header>
    <div class="flex">
      <the-nav></the-nav>
      <div class="wrapper">
        <router-view />
      </div>
    </div>
    <the-nav-mobile></the-nav-mobile>
  </div>
</template>

<script>
import TheHeader from '@/components/admin/TheHeader.vue'
import TheNav from '@/components/admin/TheNav.vue'
import TheNavMobile from '@/components/admin/TheNavMobile.vue'

export default {
  name: 'AdminWrapper',
  components: {
    TheHeader,
    TheNav,
    TheNavMobile
  },
  data(){
    return{
    }
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined"))) {
      next(false);
    }else{
      next();
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  async created() {
    await this.authCheck();
  },
  methods: {
    async authCheck() {
      this.axios
      .get(this.api + 'admin/');
    },
  },
}
</script>

<style scoped lang="scss">
  div.admin-wrapper { height: 100vh; overflow: hidden; background: #f6f7fb; }
  div.flex { display: flex; }
  div.wrapper { width: calc(100vw - 250px); height: calc(100vh - 45px); overflow-y: overlay; }

  @media (max-width: 1000px) {
    div.wrapper { width: 100vw; height: calc(100vh - 55px); }
  }
</style>
