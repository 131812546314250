<template>
  <div class="admin-content">
    <nav class="admin-the-sub-nav">
      <ul>
        <li v-for="(page, index1) in content" :key="index1">
          <button :class="{ active: activePage == index1 }" @click="changePage(index1)">{{ page.page }}</button>
        </li>
      </ul>
    </nav>
    <div class="admin-content-container" v-if="content != 0">
      <section class="content admin-box" v-for="(section, index2) in content[activePage].sections" :key="index2">
        <header class="active" @click="expandSection(activePage, index2)">
          <h4>{{ section.section }}</h4>
          <i class="material-icons">{{ getIcon(sectionsExpand[activePage][index2].value) }}</i>
        </header>
        <div class="container" v-if="sectionsExpand[activePage][index2].value">
          <div class="element" v-for="(element, index3) in section.elements" :key="index3">
            <div class="element-sub" v-for="(subElement, index4) in element.data" :key="index4">
              <div class="content" v-if="subElement.type == 'editor'">
                <h5>{{ element.name }} - {{ subElement.name }}</h5>
                <div class="editor">
                  <vue-editor v-model="subElement.content" :editor-toolbar="editorToolbars[element.type]"/>
                </div>
              </div>
            </div>
            <div class="flex-end">
              <button class="admin-default teal" @click="save(element.id, element.data)">Zapisz</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminContent',
  data(){
    return{
      content: 0,
      activePage: 0,
      sectionsExpand: [],
      test: ''
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    editorToolbars(){
      return this.$store.state.editorToolbars;
    },
  },
  created(){
    this.getContent();
  },
  methods: {
    getIcon(val){
      if(val){
        return 'keyboard_arrow_up'
      }else{
        return 'keyboard_arrow_down'
      }
    },
    changePage(index){
      this.activePage = index;
    },
    expandSection(page, section){
      if(this.sectionsExpand[page][section].value){
        this.sectionsExpand[page][section].value = 0;
      }else{
        this.sectionsExpand[page][section].value = 1;
      }
    },
    save(elementId, subelements){
      let self = this;
      this.axios
      .put(this.api + 'content',  { data: { elementId: elementId, subelements: subelements } })
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    getContent(){
      let self = this;
      this.axios
      .get(this.api + 'content', )
      .then(response => {
        if(response.data.status == 1){
          self.content = response.data.data;
          for(let i = 0; i < self.content.length; i++){
            self.sectionsExpand.push([]);
            for(let j = 0; j < self.content[i].sections.length; j++){
               self.sectionsExpand[i].push({ value: 0, })
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
  section.content { margin: 20px; overflow: auto; }
  section.content header { display: flex; justify-content: space-between; align-items: center; height: 45px; cursor: pointer; }
  section.content header h4 { margin: 0 10px; padding: 0; font-weight: 400; font-size: 18px; color: $adminBlackLight; }
  section.content header i.material-icons { display: flex; justify-content: center; align-items: center; width: 45px; height: 45px; font-size: 28px; }
  section.content header:hover, section.content header.active { background: $adminTealLight; background: $adminGradientTeal; }
  section.content header:hover h4, section.content header.active h4 { color: #fff; }
  section.content header:hover i.material-icons, section.content header.active i.material-icons { color: #fff; }
  section.content div.container div.element { margin: 20px; background: #EEEEEE; overflow: hidden; }
  section.content div.container div.content h5 { margin: 20px; padding: 0; font-weight: 400; font-size: 16px; color: $adminGrayLight; }
  section.content div.container div.content div.editor { margin: 20px; background: #fff; }
  section.content div.container div.flex-end { display: flex; justify-content: flex-end; margin: 20px; }
</style>
