import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminLogin from '../views/admin/Login.vue'
import AdminWrapper from '../views/admin/Wrapper.vue'
import AdminContent from '../views/admin/Content.vue'
import AdminSettings from '../views/admin/Settings.vue'
import AdminForm from '../views/admin/Form.vue'

import Wrapper from '../views/Wrapper.vue'
import Home from '../views/Home.vue'
import Offer from '../views/Offer.vue'
import FAQ from '../views/FAQ.vue'
import Contact from '../views/Contact.vue'
import Error404 from '../views/Error404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/oferta',
        name: 'Offer',
        component: Offer
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
      },
      {
        path: '/kontakt',
        name: 'Contact',
        component: Contact
      },
    ]
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/wrapper',
    name: 'AdminWrapper',
    component: AdminWrapper,
    redirect: {
      name: 'AdminContent'
    },
    children: [
      {
        path: '/admin/content',
        name: 'AdminContent',
        component: AdminContent,
      },
      {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: AdminSettings,
      },
      {
        path: '/admin/form',
        name: 'AdminForm',
        component: AdminForm,
      },
    ]
  }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
