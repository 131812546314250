<template>
  <div class="admin-settings">
    <div class="admin-box password">
      <header class="admin-box-header">
        <h6>Zmiana hasła</h6>
      </header>
      <input class="admin-default" type="password" placeholder="Hasło" v-model="changePasswordVars.password1" @keyup.enter="changePassword()">
      <input class="admin-default" type="password" placeholder="Powtórz Hasło" v-model="changePasswordVars.password2" @keyup.enter="changePassword()">
      <div class="flex-end">
        <button class="admin-default teal" @click="changePassword()">Zmień</button>
      </div>
    </div>
  </div>
</template>

<script>
import TheSubNav from '../../components/admin/TheSubNav.vue'

export default {
  name: 'AdminContent',
  components: {
    TheSubNav,
  },
  data(){
    return{
      changePasswordVars: {
        password1: '',
        password2: '',
      }
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  methods: {
      changePassword(){
        if(this.changePasswordVars.password1 == '' || this.changePasswordVars.password2 == ''){
          this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Hasła nie mogą być puste' });
        }else if(this.changePasswordVars.password1 != this.changePasswordVars.password2){
          this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Hasła muszą być takie same' });
        }else{
          let self = this;
          this.axios
          .put(this.api + 'admin/password',  { password: this.changePasswordVars.password1, repeatedPassword: this.changePasswordVars.password2 })
          .then(response => {
            if(response.data.status == 1){
              this.changePasswordVars.password1 = '';
              this.changePasswordVars.password2 = '';
            }
            self.$store.commit('toggleAlert', {
              visibility: 1,
              type: response.data.status,
              msg: response.data.msg
            });
          });
        }
    },
  }
}
</script>

<style scoped lang="scss">
  div.admin-settings { display: flex; padding: 10px; }
  div.password { margin: 10px; width: 400px; }
  div.password input.admin-default { margin: 20px;  width: calc(100% - 40px); font-family: 'Roboto', sans-serif; }
  div.password div.flex-end { display: flex; justify-content: flex-start; margin: 20px; }

  @media (max-width: 400px) {
    div.wrapper { width: 100vw; }
  }
</style>
