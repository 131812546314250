<template>
  <div class="admin-form">
    <div class="admin-form-container">
      <div class="info" v-if="monthsList.length == 0">
        Brak formularzy
      </div>
      <section class="admin-box form">
        <ul v-if="monthsList">
          <li v-for="(month, index) in monthsList" :key="month+index">
            {{ month.monthName }}
            {{ month.year }}
            <button class="admin-default teal" @click="downloadFile(month)">ściągnij</button>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  name: 'AdminForm',
  data(){
    return{
      monthsList: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  async created(){
    await this.getFilesList();
  },
  methods: {
    async getFilesList() {
      let self = this;
      this.axios
      .get(this.api + 'form/list')
      .then(response => {
        if(response.data.status == 1){
          self.monthsList = response.data.monthsList;
        };
      });
    },
    async downloadFile(month) {
      let self = this;
      await this.axios({
        url: this.api + 'form',
        method: 'get',
        params: {
          month: month.monthNumber,
          year: month.year
        },
        responseType: 'blob'
      })
      .then(response => {
        if(response.data.status !== 0) {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          const aEle = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.download = `${ month.monthNumber }-${ month.year }.xlsx`;
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .form {
    margin: 0 20px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $adminGrayLight;
  }
  li:last-child {
    border-bottom: none;
  }
  .info {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: $adminGrayLight;
    font-weight: 300;
    font-style: italic;
  }
</style>
