<template>
  <div class="error-404">
    <h5>404</h5>
    <h6>Ta strona nie istnieje</h6>
    <router-link to="/">Przejdź do strony głównej</router-link>
  </div>
</template>

<script>
export default {
  name: 'Error404',
}
</script>


<style scoped lang="scss">
  div.error-404 { display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 100vh; }
  div.error-404 h5 { margin: 0; padding: 0; font-size: 150px; color: $green; }
  div.error-404 h6 { margin: 0 0 50px 0; padding: 0; font-size: 30px; color: $green; font-weight: 400; }
  a { padding: 10px 50px; margin: 10px; background-color: $green; border: 2px solid $green; color: #fff; font-size: 18px; font-weight: 500; border-radius: 0px; transition: .1s; text-decoration: none;}
  a:hover { color: $green;background-color: #fff; cursor: pointer; }

  @media (max-width: 450px) {
    div.error-404 h5 { font-size: 80px; }
    div.error-404 h6 { margin: 0 0 25px 0; font-size: 22px; }
    a { padding: 8px 25px; font-size: 16px; }
  }
</style>
