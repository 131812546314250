import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API,
    adminNavMobile: 0,
    navMobile: 0,
    alert:{
      visibility: 0,
      type: 0,
      msg: '',
      site: ''
    },
    content: 0,
    editorToolbars: {
      article: [	
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
        ],
        ["blockquote",],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link",],
        ["clean"] // remove formatting button
        ]      
    },
    loaded: 0,
  },
  mutations: {
    toggleAlert(state, payload){
      state.alert.visibility = payload.visibility;
      state.alert.type = payload.type;
      state.alert.msg = payload.msg;
      state.alert.site = payload.site;
    },
    toggleAdminNavMobile(state, payload){
      state.adminNavMobile = payload;
    },
    toggleNavMobile(state, payload){
      this.state.navMobile = payload;
    },
    changeContent(state, payload){
      state.content = payload;
    },
    changeLoaded(state, payload){
      state.loaded = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
