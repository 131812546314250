<template>
   <nav id="admin-the-nav-mobile" v-if="adminNavMobile">
     <ul>
       <li>
          <router-link to="/" @click.native="close()">
            <p>Strona główna</p>
            <i class="material-icons">home</i>
          </router-link>
       </li>
       <li>
          <router-link :to="{ name: 'AdminForm' }" :class="{ active: checkRoute('AdminForm') }" @click.native="close()">
            <p>Formularze</p>
            <i class="material-icons">list_alt</i>
          </router-link>
       </li>
       <li>
          <router-link :to="{ name: 'AdminContent' }" :class="{ active: checkRoute('AdminContent') }" @click.native="close()">
            <p>Treści</p>
            <i class="material-icons">edit</i>
          </router-link>
       </li>
       <li>
          <router-link :to="{ name: 'AdminSettings' }" :class="{ active: checkRoute('AdminSettings') }" @click.native="close()">
            <p>Ustawienia</p>
            <i class="material-icons">settings</i>
          </router-link>
       </li>
     </ul>
  </nav>
</template>

<script>
export default {
  name: 'AdminTheNavMobile',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  computed:{
    adminNavMobile(){
      return this.$store.state.adminNavMobile;
    },
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
    }
  },
  methods:{
    close(){
      this.$store.commit('toggleAdminNavMobile', 0);
      document.body.style = "overflow-y: overlay";
    },
    checkRoute(value){
      if(this.activeRoute.search(value) != -1){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  nav#admin-the-nav-mobile { width: 100vw; min-height: calc(100vh - 55px); position: absolute; top: 55px; background: $adminTealLight; background: $adminGradientTeal; overflow-y: auto; }
  nav#admin-the-nav-mobile ul { margin: 0; padding: 0; list-style: none; border-top: 2px solid rgba(255, 255, 255, 0.3);}
  nav#admin-the-nav-mobile ul li a { display: flex; height: 60px; align-items: center; justify-content: space-between; text-decoration: none; border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  nav#admin-the-nav-mobile ul li a i.material-icons { display: flex; justify-content: center; align-items: center; width: 55px; height: 55px; color: rgba(255, 255, 255, 0.9); font-size: 21px; }
  nav#admin-the-nav-mobile ul li a p { margin: 0 20px; padding: 0; font-weight: 400; color: rgba(255, 255, 255, 0.9); font-size: 16px; }
  nav#admin-the-nav-mobile ul li a.active { background: rgba(255, 255, 255, 0.2); }

  @media (min-width: 1000px) {
    nav#admin-the-nav-mobile { display: none; }
  }
</style>
