<template>
    <div class="contact" v-if="content">
        <section>
            <div class="form-wrapper">
                <h3 v-html="$decodeHTML($sanitize($getContent(page, 'formularz', 'nagłówek', 'treść')))"></h3>
                <div class="form">
                    <div class="icons">
                        <div class="icon-wrapper" @click="selectedForm = 1" :class="{ selected: selectedForm === 1 }">
                            <div class="icon"></div>
                            <h3>OSOBA FIZYCZNA</h3>
                        </div>
                        <div class="icon-wrapper" @click="selectedForm = 2" :class="{ selected: selectedForm === 2 }">
                            <div class="icon"></div>
                            <h3>FIRMA</h3>
                        </div>
                    </div>
                    <form v-if="selectedForm === 1">
                        <div class="row">
                            <input type="text" class="client-page" placeholder="imię i nazwisko*" v-model="naturalPerson.nameAndSurname" :class="{ error: validationField === 2 }">
                            <input type="text" class="client-page" placeholder="e-mail*" v-model="naturalPerson.email" :class="{ error: validationField === 4 }">
                        </div>
                        <div class="row">
                            <input type="text" class="client-page" placeholder="telefon" v-model="naturalPerson.phone" :class="{ error: validationField === 5 }">
                            <v-select class="client-page select" placeholder="województwo" :searchable="false" :options="voivodeships" v-model="naturalPerson.voivodeship"></v-select>
                        </div>
                        <div class="row">
                            <v-select class="client-page" :searchable="false" placeholder="preferowana forma kontaktu" :options="contactForm" v-model="naturalPerson.contactForm"></v-select>
                        </div>
                        <textarea type="text" class="client-page question" placeholder="zapytanie" v-model="naturalPerson.question" :class="{ error: validationField === 6 }" />
                    </form>
                    <form v-if="selectedForm === 2">
                        <div class="row">
                            <input type="text" class="client-page" placeholder="nazwa firmy*" v-model="company.name" :class="{ error: validationField === 1 }">
                            <input type="text" class="client-page" placeholder="imię i nazwisko*" v-model="company.nameAndSurname" :class="{ error: validationField === 2 }">
                        </div>
                        <div class="row">
                            <input type="text" class="client-page" placeholder="NIP" v-model="company.nip" :class="{ error: validationField === 3 }">
                            <input type="text" class="client-page" placeholder="e-mail*" v-model="company.email" :class="{ error: validationField === 4 }">
                        </div>
                        <div class="row">
                            <input type="text" class="client-page" placeholder="telefon" v-model="company.phone" :class="{ error: validationField === 5 }">
                            <v-select class="client-page select" placeholder="województwo" :searchable="false" :options="voivodeships" v-model="company.voivodeship"></v-select>
                        </div>
                        <div class="row">
                            <input type="number" class="client-page single" placeholder="ilość posiadanych samochodów" v-model="company.carsQuantity"/>
                            <div class="input-quantity">
                                <div class="arrow-up" @click="increaseCars()"></div>
                                <div class="arrow-down" @click="decreaseCars()"></div>
                            </div>
                        </div>
                        <div class="row">
                            <v-select class="client-page" :searchable="false" placeholder="preferowana forma kontaktu" :options="contactForm" v-model="company.contactForm"></v-select>
                        </div>
                        <textarea type="text" class="client-page question" placeholder="zapytanie" v-model="company.question" :class="{ error: validationField === 6 }"/>
                    </form>
                    <p>* - pola obowiązkowe</p>
                    <div class="checkbox-wrapper">
                        <button class="checkbox" @click="checkPersonalDataProcessing" :class="{ checked: personalDataProcessing, error: validationField === 7 }">
                            <i class="material-icons">done</i>
                        </button>
                        <p>*Wyrażam zgodę na przetwarzanie moich danych osobowych przez LOTCARS Krzysztof Lotko zgodnie z ustawą o ochronie danych osobowych w celu przedstawienia odpowiedzi na wysłane zapytanie. Akceptuję <a href="./polityka_prywatnosci_i_regulamin.pdf" target="_blank">politykę prywatności i ogólne warunki korzystania ze strony internetowej (regulamin).</a></p>
                    </div>
                    <h5 class="error">{{ validationText }}</h5>
                    <button class="default" @click="sendForm" :class="{ loading: loading }">
                        <div class="lds-ellipsis" v-if="loading"><div></div><div></div><div></div><div></div></div>
                        <span v-if="!loading">wyślij</span>
                    </button>
                </div>
            </div>
            <article>
                <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'nagłówek', 'tytuł')))"></h3>
                <div class="box-wrapper">
                    <div class="box">
                        <div class="icon"></div>
                        <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'box1', 'podtytuł')))"></h4>
                    </div>
                    <div class="box">
                        <div class="icon"></div>
                        <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'box2', 'podtytuł')))"></h4>
                        <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'box2', 'treść')))"></p>
                    </div>
                    <div class="box">
                        <div class="icon"></div>
                        <h4 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'box3', 'podtytuł')))"></h4>
                        <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'box3', 'treść')))"></p>
                    </div>
                </div>
            </article>
        </section>
        <alert></alert>
    </div>
</template>

<script>
    import Alert from '../components/Alert.vue';

    export default {
        name: 'Contact',
        components: {
            Alert,
        },
        data() {
            return {
                page: 'Kontakt',
                naturalPerson: {
                    nameAndSurname: '',
                    email: '',
                    phone: '',
                    voivodeship: '',
                    contactForm: '',
                    question: ''
                },
                company: {
                    name: '',
                    nameAndSurname: '',
                    nip: '',
                    email: '',
                    phone: '',
                    voivodeship: '',
                    carsQuantity: '',
                    contactForm: '',
                    question: ''
                },
                selectedForm: 1,
                voivodeships: ['dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'],
                contactForm: ['telefonicznie', 'e-mail'],
                validationText: '',
                validationField: 0,
                loading: 0,
                personalDataProcessing: 0,
            }
        },
        computed: {
            content() {
                return this.$store.state.content;
            },
            api(){
                return this.$store.state.api;
            },
        },
        methods: {
            increaseCars() {
                if(this.company.carsQuantity === '') {
                    this.company.carsQuantity = 1;
                } else {
                    this.company.carsQuantity++;
                };
            },
            decreaseCars() {
                if(this.company.carsQuantity === '') {
                    this.company.carsQuantity = 0;
                } else if(this.company.carsQuantity !== 0) {
                    this.company.carsQuantity--;
                };
            },
            checkPersonalDataProcessing() {
                if(!this.personalDataProcessing && this.validationField === 7) {
                    this.validationField = 0;
                }
                this.personalDataProcessing = !this.personalDataProcessing;
            },
            async sendForm() {
                this.loading = 1;
                let message;
                if(!this.personalDataProcessing) {
                    this.validationText = 'Wymagane jest zaakceptowanie zgody na przetwarzanie danych';
                    this.validationField = 7;
                    this.loading = 0;
                    return;
                };
                if(this.selectedForm === 1) {
                    message = this.naturalPerson;
                    message.form = 1;
                } else if(this.selectedForm === 2) {
                    message = this.company;
                    message.form = 2;
                } else {
                    this.loading = 0;
                    return;
                };
                let self = this;
                await this.axios
                .post(this.api + 'mailing/', { message: message })
                .then(response => {
                    if(response.data.status === 1) {
                        self.validationText = '';
                        self.validationField = 0;
                        this.$store.commit('toggleAlert', {
                            visibility: 1,
                            type: 1,
                            msg: 'Pomyślnie wysłano formularz',
                            site: 'client'
                        });
                    } else {
                        self.validationText = response.data.msg;
                        self.validationField = response.data.validation;
                    };
                });
                this.loading = 0;
            },
        },
    }
</script>

<style lang="scss" scoped>
    div.form-wrapper { 
        width: 1000px; 
        margin: 50px auto; 
    }
    div.form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    div.form p {
        width: 100%;
        text-align: left;
    }
    div.icons {
        display: flex;
        justify-content: center;
        margin: 15px 0;
    }
    div.icons div:nth-child(1) {
        margin-right: 10px;
    }
    div.icons div:nth-child(2) {
        margin-left: 10px;
    }
    div.icon-wrapper {
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0;
    }
    div.icon-wrapper:hover {
        background-color: $green;
        cursor: pointer;
    }
    div.icon-wrapper.selected {
        background-color: $green;
    }
    div.icon-wrapper.selected h3 {
        color: #fff;
    }
    div.icon-wrapper:hover h3 {
        color: #fff;
    }
    div.icon-wrapper div.icon {
        width: 59px;
        height: 52px;
        margin: 0;
    }
    div.icon-wrapper:nth-child(1) div.icon {
        background-image: url('../assets/contact/1-05.png');
    }
    div.icon-wrapper:nth-child(2) div.icon {
        background-image: url('../assets/contact/1-03.png');
    }
    div.icon-wrapper:nth-child(1):hover div.icon, div.icon-wrapper:nth-child(1).selected div.icon {
        background-image: url('../assets/contact/A-05.png');
    }
    div.icon-wrapper:nth-child(2):hover div.icon, div.icon-wrapper:nth-child(2).selected div.icon {
        background-image: url('../assets/contact/B-03.png');
    }
    div.icon-wrapper h3 {
        font-size: 15px;
        text-align: center;
    }
    form {
        width: 1000px;
    }
    textarea.question {
        height: 200px;
        margin-bottom: 0;
    }
    div.row {
        display: flex;
        position: relative;
    }
    div.row input:nth-child(1) {
        max-width: 495px;
        margin-right: 5px;
    }
    div.row input:nth-child(2) {
        max-width: 495px;
        margin-left: 5px;
    }
    div.row .select:nth-child(2) {
        max-width: 495px;
        margin-left: 5px;
    }
    div.row input.single {
        max-width: 100%;
        margin-right: 0;
    }
    div.box-wrapper {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 50px;
    }
    div.box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    div.box:nth-child(1), div.box:nth-child(3) {
        width: 300px;
    }
    div.box div.icon {
        width: 59px;
        height: 52px;
        margin: 0;
    }
    div.box:nth-child(1) div.icon {
        background-image: url('../assets/contact/1-01.png');
    }
    div.box:nth-child(2) div.icon {
        background-image: url('../assets/contact/1-02.png');
    }
    div.box:nth-child(3) div.icon {
        background-image: url('../assets/contact/1-04.png');
    }
    div.box p {
        color: $gray;
        text-align: center;
    }

    div.input-quantity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: absolute;
        right: 15px;
    }
    div.input-quantity div:hover {
        cursor: pointer;
    }
    div.input-quantity div:active {
        animation: pulse .1s ease-out;
    }
    button.default {
        width: 151px;
        height: 45px;
    }
    button.default.loading:hover {
        background-color: $green;
        cursor: initial;
    }
    div.checkbox-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-top: 10px;
    }
    div.checkbox-wrapper p {
        margin: 0;
        margin-left: 5px;
        text-align: start;
    }
    div.checkbox-wrapper p a { color: $green; text-decoration: none; }
    div.checkbox-wrapper p a:hover { text-decoration: underline; }  
    .arrow-up {
        margin-bottom: 5px;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        border-bottom: 9px solid $green;
    }
    .arrow-down {
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        
        border-top: 9px solid $green;
    }
    @keyframes pulse {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.5);
        }
    }
    @media (max-width: 1000px) {
        div.form-wrapper { 
            width: calc(100% - 40px);
        }
        form {
            width: calc(100% - 40px);
            margin: 0 auto;
        }
        div.row {
            flex-direction: column;
        }
        div.row input:nth-child(1) {
            max-width: 100%;
            margin-right: 0;
        }
        div.row input:nth-child(2) {
            max-width: 100%;
            margin-left: 0;
        }
        div.row .select:nth-child(2) {
            max-width: 100%;
            margin-left: 0;
        }
    }
    @media (max-width: 800px) {
        div.box-wrapper {
            flex-direction: column;
            align-items: center;
        }
        div.box:nth-of-type(1) {
            margin-top: 0;
        }
        div.box {
            margin-top: 50px;
        }
    }
    @media (max-width: 300px) {
        div.box:nth-child(1), div.box:nth-child(3) {
            width: 100%;
        }
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 20px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: -7px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: -7px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 17px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 41px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
</style>
