<template>
  <div id="app">
    <router-view/>
    <alert></alert>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export default {
  name: 'App',
  components: {
    Alert,
  },
  data(){
    return{
      currentRequest: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    content(){
      return this.$store.state.content;
    },
  },
  created(){
    let self = this;
    const refreshAuthLogic = failedRequest => this.axios.get(self.api + 'admin/token', { headers: { Authorization: localStorage.refreshToken } }).then(tokenRefreshResponse => {
      if(tokenRefreshResponse.data.status == 1){
        localStorage.accessToken = tokenRefreshResponse.data.accessToken;
        failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
      }else{
        self.logout();
      }
    });

    createAuthRefreshInterceptor(this.axios, refreshAuthLogic, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: false,
    });
    this.axios.interceptors.request.use(request => {
        if(typeof request.headers['notoken'] === 'undefined' && request.url != this.api + 'admin/token'){
          request.headers['Authorization'] = self.getAccessToken();
        }
        return request;
    });
    this.getContent();
  },
  methods:{
    logout(){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.$router.push('/admin');
    },
    getAccessToken(){
      return localStorage.getItem('accessToken');
    },
    getContent(){
      let self = this;
      this.axios
      .get(this.api + 'content', )
      .then(response => {
        if(response.data.status == 1){
          this.$store.commit('changeContent', response.data.data);
        }
      });
    }
  }
}
</script>

<style lang="scss">
  @import "vue-select/src/scss/vue-select.scss";
  div#app { margin: 0; padding: 0; }
</style>
