<template>
    <div class="faq" v-if="content">
        <section class="articles">
            <article>
                <div class="content">
                    <div>
                        <transition name="fade">
                        <div class="image" :load="staticLoaded()" v-show="staticImg">
                            <div class="line"></div>
                        </div>
                        </transition>
                    </div>
                    <div class="text-wrapper">
                        <div class="text">
                            <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit1', 'tytuł')))"></h3>
                            <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit1', 'treść')))"></p>
                        </div>
                        <div class="text">
                            <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit2', 'tytuł')))"></h3>
                            <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit2', 'treść')))"></p>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit3', 'tytuł')))"></h3>
                    <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit3', 'treść')))"></p>
                </div>
                <div class="content">
                    <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit4', 'tytuł')))"></h3>
                    <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit4', 'treść')))"></p>
                </div>
                <div class="content">
                    <h3 v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit5', 'tytuł')))"></h3>
                    <p v-html="$decodeHTML($sanitize($getContent(page, 'artykuł', 'akapit5', 'treść')))"></p>
                </div>
            </article>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'FAQ',
        data() {
            return {
                page: 'FAQ',
                staticImg: 0,
            }
        },
        computed: {
            content() {
                return this.$store.state.content;
            }
        },
        methods: {
            staticLoaded(){
                this.staticImg = 1;
            }
        }
    }
</script>

<style lang="scss" scoped>
::v-deep a {
    color: $green;
    text-decoration: none;
}
article div.content {
    margin-bottom: 60px;
}
div.content:nth-of-type(1) {
    display: flex;
    height: 350px;
}
div.content div.text-wrapper {
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}
div.content:nth-child(1) div.text:nth-child(2){
    width: 29vw;
}
div.image {
    position: absolute;
    background: transparent url("../assets/faq.webp") no-repeat 0 50%;
    height: 350px;
    max-width: 800px;
    width: 60vw;
    right: 0;
}
.line {
    position: absolute;
    right: 90%;
    background-color: $gray;
    transform: skew(-31deg);
    height: 350px;
    width: 2px;
}
@media (max-width: 1500px) {
    div.image {
        width: 50vw;
    }
}
@media (max-width: 1400px) {
    div.image {
        width: 40vw;
    }
    div.content div.text-wrapper {
        width: 50vw;
    }
    div.content:nth-child(1) div.text:nth-child(2){
        width: 40vw;
    }
}
@media (max-width: 1000px) {
    article {
        width: 100vw;
        margin: 0;
    }
    div.content {
        width: calc(100% - 40px);
        margin: 20px auto;
    }
    div.content:nth-of-type(1) {
        width: calc(100% - 40px);
        margin: 0;
    }
    div.content:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        height: initial;
    }
    div.content:nth-of-type(1) div:nth-of-type(1) {
        margin-top: 20px;
        position: relative;
        width: 100vw;
    }
    div.image {
        background: transparent url("../assets/faq_medium.webp") no-repeat 50% 50%;
        background-size: 1000px;
        width: 100vw;
        max-width: 100vw;
        height: 300px;
        position: absolute; 
        top: 0; 
        left: 0;
    }
    .line { display: none; }
    div.content div.text-wrapper {
        width: 100%;
        margin: 20px;
    }
    div.content:nth-child(1) div.text:nth-child(2){
        width: 100%;
    }
    div.content:nth-child(1) div.text:nth-child(1){
        width: 100%;
    }
}
@media (max-width: 450px) {
    div.image { height: 250px; background: transparent url("../assets/faq_small.webp") no-repeat 50% 50%; }
}
</style>
