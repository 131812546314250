import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue2Editor from "vue2-editor";
import VueSanitize from "vue-sanitize";
import pageContent from './mixins/pageContent';
import vSelect from 'vue-select';
import VueGtag from "vue-gtag";

const defaultOptions = {
  allowedTags: ['a', 'b', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'u', 'strong', 'sup', 'ul', 'li'],
  allowedAttributes: {
    'a': [ 'href' ],
    '*': [ 'class' ]
  },
  disallowedTagsMode: 'discard',
};

Vue.use(VueAxios, axios)
Vue.use(Vue2Editor);
Vue.use(VueSanitize, defaultOptions);
Vue.use(VueGtag, {
  config: { id: "G-K2X94XSFVL" }
});

Vue.component('v-select', vSelect);

Vue.config.productionTip = false

Vue.mixin(pageContent);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
